.contact__header{
    display: inline-block;
    margin-bottom: 4.5rem;
}

.content__page{
    width: 75vw;
}

.contact__title{
    font-size: 5rem;
    color: black;
    position: relative;
}

.contact__color{
    display: inline-block;
    text-align: center;
    height: 9.5rem;
    width: 9.5rem;
    line-height: 9.5rem;
    background-color: var(--color-tertiary);
    border-radius: 6rem;
    color: black;
}

.contact__container{
    display: flex;
    flex-direction: row;
}

.contact__form-box{
    width: 100%;
    padding: 0 1rem;
}

.contact__form-header{
    display: inline-block;
    padding-right: 1.2rem;
    margin-bottom: 2.5rem;
    position: relative;
}

.form-header__title{
    font-size: 2.1rem;
    margin-bottom: 1rem;
    color: black;
}

.form__container{
    display: block;
}

.form__top{
    display: flex;
    flex-wrap: wrap;
}

.form__group{
    position: relative;
    margin-bottom: 2.5rem;
}

.form__group--1{
    flex-basis: calc(50% - 2rem);
    margin-right: 2rem;
}

.form__group--2{
    flex-basis: calc(50% - 2rem);
}

.form__group--3{
    flex-basis: calc(100% - 2rem);
}

.form__input{
    position: relative;
    width: 100%;
    height: 4.2rem;
    display: block;
    font-size: 1.35rem;
    padding: 1rem 1.2rem;
    border: 0.1rem solid grey;
    border-radius: 0.5rem;
    outline: none;
    box-shadow: 0 1rem 1rem -8px rgba(0, 0, 0, 0.1);
    background: 0 0;
    background-color: transparent;
    line-height: 2.1rem;
    z-index: 1;
}

.form__input:focus{
    border: 0.2rem solid var(--color-secondary);
}

.form__label{
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    z-index: -1;
    color: var(--color-company);
    transition: all 300ms ease-in-out;
}

.form__label--error{
    color: #e74c3c !important;
}

.form__input:focus~label,
.form__input:not(:placeholder-shown)~label{
    top: -2rem;
    left: 0;
    z-index: 1;
    font-size: 1.3rem;
    color: var(--color-secondary);
}

.form__input:focus::-webkit-input-placeholder{
    color: transparent;
}

.form__input:focus::-moz-placeholder{
    color: transparent;
}

.form__bottom{
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
}

.form__group--textarea{
    flex-basis: calc(100% - 2rem);
    height: 30rem;
}

.form__input--textarea{
    display: block;
    resize: none;
    height: 100%;
}

.captcha{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.form__button{
    padding: 1rem 3rem;
    font-size: 1.5rem;
    border: 0.2rem solid var(--color-secondary);
    margin-bottom: 5rem;
    color: var(--color-secondary);
    background-color: white;
    border-radius: 3rem;
    box-shadow: 0 1rem 1rem -8px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.form__button:hover{
    background-color: var(--color-secondary);
    color: white;
}

.modal-loading {
    margin:auto;
}