@font-face{
    font-family: 'OpenSans';
    src: url('./OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: 'OpenSans';
    src: url('./OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
}