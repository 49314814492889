@media (max-width: 1250px) {
    .about__personal-info{
        display: grid;
        grid-template-areas: 
            "aboutMe aboutMe"
            "personalInfo personalInfo";
        grid-template-columns: 100%;
    }
    
    .personal-info__bio{
        grid-area: aboutMe;
        width: 60vw;
    }
    
    .personal-info__data{
        grid-area: personalInfo;
        padding: 0;
        margin-top: 2.5rem;
    }
    
    .personal-info__list{
        position: relative;
        width: 55rem;
        top: 0rem;
        margin-left: 0rem;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0rem 2rem;
    }
    
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(30% - 3rem);
    }
}

@media (max-width: 1100px) {    
    .personal-info__bio{
        grid-area: aboutMe;
        width: 55vw;
    }
}

@media (max-width: 1060px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(50% - 3rem);
    }
}

@media (max-width: 1060px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(40% - 3rem);
    }
}

@media (max-width: 980px) {
    .personal-info__bio{
        width: 80vw;
    }

    .about__title{
        font-size: 5.3rem;
    }

    .about__personal-info{
        font-size: 2.5rem;
        line-height: 3.7rem;
    }

    .skills__title{
        font-size: 5rem;
    }

    .lenguages__title,
    .databases__title,
    .programs__title{
        font-size: 3rem;
    }

    .icon__icon{
        font-size: 4.8rem;
    }

    .icon__icon:hover{
        font-size: 5.5rem;
    }

    .lenguage__title,
    .database__title,
    .program__title{
        font-size: 2.3rem;
        line-height: 2.8rem;
    }
}

@media (max-width: 900px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(50% - 3rem);
    }
}

@media (max-width: 800px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(40% - 3rem);
    }
}

@media (max-width: 685px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(30% - 3rem);
    }
}

@media (max-width: 550px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(25% - 3rem);
    }
}

@media (max-width: 475px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(20% - 3rem);
    }
}

@media (max-width: 365px) {
    /*HABILIDADES*/    
    .lenguages__lenguage,
    .databases__database,
    .programs__program{
        flex-basis: calc(15% - 3rem);
    }
}