.content__about,
.content__education,
.content__experience,
.content__contact{
    display: block;
    min-height: 80%;
    max-height: 100%;
    margin: 6rem;
    margin-right: 3rem;
}

.content__about{
    padding-bottom: 120px;
}