@media (min-width: 2600px) {
  #download__button--banner{
    height: 5vw;
    width: 18vw;
    font-size: 2vw;
    line-height: 2.1;
  }

  #download__button--banner:hover{
      font-size: 2.1vw;
      line-height: 2.2;
  }

  .image__item--1 {
    width: 28vw;
  }
  
  .image__item--1 .image {
    width: 28vw;
  }

  .image__item--2 {
    width: 7.5vw;
  }
}

@media (max-width: 1000px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 4vw;
  }
  
  .banner__subtitle {
    font-size: 2.5vw;
  }

  #download__button--banner{
    height: 6vw;
    width: 25vw;
    font-size: 2.5vw;
    line-height: 2;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 85vw;
  }

  .image__item--1 {
    width: 27vw;
  }
  
  .image__item--2 {
    width: 7.3vw;
  }

  .image__item--3 {
    right: 50vw;
    width: 11vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 3.5vw;
  }
  
  .image__item--4 {
    bottom: 20vw;
    left: 35vw;
  }
  
  .image__item--5 {
    bottom: 24vw;
    left: 42vw;
  }
  
  .image__item--6 {
    bottom: 9vw;
    left: 33vw;
  }
  
  .image__item--7 {
    bottom: 27vw;
    right: 48vw;
  }
  
  .image__item--8 {
    bottom: 20vw;
    right: 32vw;
  }
  
  .image__item--9 {
    bottom: 13vw;
    right: 28vw;
  }
  
  .image__item--10 {
    bottom: 35vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 30vw;
    right: 3vw;
  }
}

@media (max-width: 600px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 5vw;
  }
  
  .banner__subtitle {
    font-size: 3vw;
  }

  #download__button--banner{
    height: 8vw;
    width: 30vw;
    font-size: 3.5vw;
    line-height: 3.5rem;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 85vw;
  }

  .image__item--1 {
    width: 28vw;
  }
  
  .image__item--2 {
    width: 7.5vw;
  }

  .image__item--3 {
    right: 50vw;
    width: 12vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 4vw;
  }
  
  .image__item--4 {
    bottom: 20vw;
    left: 35vw;
  }
  
  .image__item--5 {
    bottom: 24vw;
    left: 42vw;
  }
  
  .image__item--6 {
    bottom: 9vw;
    left: 33vw;
  }
  
  .image__item--7 {
    bottom: 27vw;
    right: 48vw;
  }
  
  .image__item--8 {
    bottom: 20vw;
    right: 32vw;
  }
  
  .image__item--9 {
    bottom: 13vw;
    right: 28vw;
  }
  
  .image__item--10 {
    bottom: 35vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 30vw;
    right: 3vw;
  }
}

@media (max-height: 430px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 4.5vw;
  }
  
  .banner__subtitle {
    font-size: 2.7vw;
  }

  #download__button--banner{
    height: 6vw;
    width: 25vw;
    font-size: 2.5vw;
    line-height: 2;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 75vw;
  }

  .image__item--1 {
    width: 25vw;
  }
  
  .image__item--2 {
    width: 6.5vw;
  }

  .image__item--3 {
    right: 47vw;
    width: 10vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 3.5vw;
  }
  
  .image__item--4 {
    bottom: 15vw;
    left: 35vw;
  }
  
  .image__item--5 {
    bottom: 19vw;
    left: 42vw;
  }
  
  .image__item--6 {
    bottom: 6vw;
    left: 33vw;
  }
  
  .image__item--7 {
    bottom: 22vw;
    right: 48vw;
  }
  
  .image__item--8 {
    bottom: 17vw;
    right: 32vw;
  }
  
  .image__item--9 {
    bottom: 10vw;
    right: 28vw;
  }
  
  .image__item--10 {
    bottom: 31vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 27vw;
    right: 3vw;
  }
}

@media (max-height: 385px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 4.5vw;
  }
  
  .banner__subtitle {
    font-size: 2.7vw;
  }

  #download__button--banner{
    height: 6vw;
    width: 25vw;
    font-size: 2.5vw;
    line-height: 2;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 75vw;
  }

  .image__item--1 {
    width: 25vw;
  }
  
  .image__item--2 {
    width: 6.5vw;
  }

  .image__item--3 {
    right: 47vw;
    width: 10vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 3.5vw;
  }
  
  .image__item--4 {
    bottom: 15vw;
    left: 35vw;
  }
  
  .image__item--5 {
    bottom: 19vw;
    left: 42vw;
  }
  
  .image__item--6 {
    bottom: 6vw;
    left: 33vw;
  }
  
  .image__item--7 {
    bottom: 22vw;
    right: 48vw;
  }
  
  .image__item--8 {
    bottom: 17vw;
    right: 32vw;
  }
  
  .image__item--9 {
    bottom: 10vw;
    right: 28vw;
  }
  
  .image__item--10 {
    bottom: 31vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 27vw;
    right: 3vw;
  }
}

@media (max-height: 350px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 4vw;
  }
  
  .banner__subtitle {
    font-size: 2.5vw;
  }

  #download__button--banner{
    height: 5.5vw;
    width: 23vw;
    font-size: 2.5vw;
    line-height: 2;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 70vw;
  }

  .image__item--1 {
    width: 22vw;
  }
  
  .image__item--2 {
    width: 6vw;
  }

  .image__item--3 {
    right: 45vw;
    width: 9vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 3.3vw;
  }
  
  .image__item--4 {
    bottom: 15vw;
    left: 40vw;
  }
  
  .image__item--5 {
    bottom: 19vw;
    left: 47vw;
  }
  
  .image__item--6 {
    bottom: 6vw;
    left: 38vw;
  }
  
  .image__item--7 {
    bottom: 22vw;
    right: 43vw;
  }
  
  .image__item--8 {
    bottom: 17vw;
    right: 27vw;
  }
  
  .image__item--9 {
    bottom: 10vw;
    right: 23vw;
  }
  
  .image__item--10 {
    bottom: 28vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 23vw;
    right: 3vw;
  }
}

@media (max-height: 345px) {
  .banner__content {
    width: 80%;
  }

  .banner__title {
    font-size: 3.5vw;
  }
  
  .banner__subtitle {
    font-size: 2.2vw;
  }

  #download__button--banner{
    height: 5.2vw;
    width: 22vw;
    font-size: 2.5vw;
    line-height: 2;
  }

  #download__button--banner:hover{
      font-size: 2.6vw;
      line-height: 2.1;
  }
  
  .banner__pattern {
    width: 70vw;
  }

  .image__item--1 {
    width: 20vw;
  }
  
  .image__item--2 {
    width: 5.5vw;
  }

  .image__item--3 {
    right: 45vw;
    width: 9vw;
  }
  
  .image__item--4 .image,
  .image__item--5 .image,
  .image__item--6 .image,
  .image__item--7 .image,
  .image__item--8 .image,
  .image__item--9 .image,
  .image__item--10 .image,
  .image__item--11 .image {
    width: 3.2vw;
  }
  
  .image__item--4 {
    bottom: 14vw;
    left: 40vw;
  }
  
  .image__item--5 {
    bottom: 18vw;
    left: 47vw;
  }
  
  .image__item--6 {
    bottom: 5.5vw;
    left: 38vw;
  }
  
  .image__item--7 {
    bottom: 21vw;
    right: 43vw;
  }
  
  .image__item--8 {
    bottom: 16vw;
    right: 27vw;
  }
  
  .image__item--9 {
    bottom: 9vw;
    right: 23vw;
  }
  
  .image__item--10 {
    bottom: 25vw;
    right: 7vw;
  }
  
  .image__item--11 {
    bottom: 21vw;
    right: 3vw;
  }
}