.content__education--language{
    display: block;
    max-height: 100vh;
    
    height: auto;
    margin: 6rem;
    margin-right: 3rem;
}

.education__title{
    font-size: 5rem;
    color: black;
    position: relative;
}

.education__color{
    display: inline-block;
    text-align: center;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
    background-color: var(--color-tertiary);
    border-radius: 6rem;
    color: black;
}