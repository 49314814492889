/*VARIABLES*/
:root{
    --color-primary: #0f054c;
    --color-secondary: #6464dd;
    --color-tertiary: #42e8e0;
    --color-text-body: #525260;
    --color-text: #fff;
    --color-subtitle: #a0d4d2;
}

/*ESTILOS GENERALES*/
html{
    font-size: 10px;
    overflow-x: hidden;
}

body{
    width: 100%;
    height: 100%;

    font-size: 1.6rem;
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--color-text-body);
}

/*ESTRUCTURA*/
#root{
    width: 100%;
    height: 100%;
}

@media (max-width: 3900px){
    html{
        font-size: 19px;
    }
}

@media (max-width: 3800px){
    html{
        font-size: 18.5px;
    }
}

@media (max-width: 3700px){
    html{
        font-size: 18px;
    }
}

@media (max-width: 3600px){
    html{
        font-size: 17.5px;
    }
}

@media (max-width: 3500px){
    html{
        font-size: 17px;
    }
}

@media (max-width: 3400px){
    html{
        font-size: 16.5px;
    }
}

@media (max-width: 3300px){
    html{
        font-size: 16px;
    }
}

@media (max-width: 3200px){
    html{
        font-size: 15.5px;
    }
}

@media (max-width: 3100px){
    html{
        font-size: 15.5px;
    }
}

@media (max-width: 3000px){
    html{
        font-size: 15px;
    }
}

@media (max-width: 2900px){
    html{
        font-size: 14.5px;
    }
}

@media (max-width: 2800px){
    html{
        font-size: 14px;
    }
}

@media (max-width: 2700px){
    html{
        font-size: 13.5px;
    }
}

@media (max-width: 2600px){
    html{
        font-size: 13px;
    }
}

@media (max-width: 2500px) {
    html{
        font-size: 12.5px;
    }
}

@media (max-width: 2400px) {
    html{
        font-size: 12.5px;
    }
}

@media (max-width: 2300px) {
    html{
        font-size: 12px;
    }
}

@media (max-width: 2200px) {
    html{
        font-size: 11.5px;
    }
}

@media (max-width: 2100px) {
    html{
        font-size: 11px;
    }
}

@media (max-width: 2000px) {
    html{
        font-size: 10.5px;
    }
}

@media (max-width: 1900px) {
    html{
        font-size: 10px;
    }
}

@media (max-width: 1800px) {
    html{
        font-size: 9.5px;
    }
}

@media (max-width: 1537px) and (max-height: 732px) {
    html{
        font-size: 9px;
    }
}

@media (max-width: 1200px) {
    html{
        font-size: 9px;
    }
}

@media (max-width: 1000px) {
    html{
        font-size: 8px;
    }
}

@media (max-width: 800px) {
    html{
        font-size: 7.5px;
    }
}

@media (max-width: 750px) {
    html{
        font-size: 7px;
    }
}

@media (max-width: 600px) {
    html{
        font-size: 6.5px;
    }
}

@media (max-width: 500px) {
    html{
        font-size: 6px;
    }
}

@media (max-width: 450px) {
    html{
        font-size: 5.5px;
    }
}

@media (max-width: 380px) {
    html{
        font-size: 5px;
    }
}

@media (max-width: 350px) {
    html{
        font-size: 4.5px;
    }
}