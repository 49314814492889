.layout__aside {
    display: flex;
    position: absolute;
    z-index: 999;
    max-width: 50rem;
    width: 100%;
    height: calc(100vh - 8rem);
    min-height: calc(100vh - 8rem);
    top: 8rem;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: auto;
    border-radius: 0;
    transition: all 300ms ease-in-out;
    margin-right: 0;
    background-color: var(--color-primary);
    overflow: auto;
}

.aside__personal-info--responsive{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    min-height: 100%;
    min-width: 30rem;

    background-color: var(--color-primary);
    color: white;

    margin-left: 10rem;
}

.personal-info__name--responsive{
    margin-top: 4rem;
    font-size: 3.7rem;
    font-weight: bold;
}

.personal-info__job--aside{
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 2.2rem;
    line-height: 1.3;
}

/*Opciones*/
.nav__list--aside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.nav__item--aside{
    position: relative;
    margin-top: 2.5vw;
}

.nav__item--aside:last-child{
    margin-bottom: 2vw;
}

.personal-info__links--responsive{
    margin-bottom: 3.5rem;
}

.links__social--responsive{
    margin-top: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.social__option--responsive{
    height: 4.5rem;
    width: 4.5rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    opacity: 0.9;
    border-radius: 3rem;
    transition: all 300ms linear;
}

.social__option--responsive:hover{
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
}

.personal-info__footer--aside {
    margin-top: auto; /* Empuja este elemento al final del contenedor */
    margin-bottom: 3rem;
    justify-content: flex-end;
}

.footer__Designer--responsive{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.7rem;
    margin-bottom: 1rem;
}

.footer__social--responsive{
    height: 3rem;
    width: 12.5rem;
    color: white;
    line-height: 3rem;
    opacity: 0.9;
    border-radius: 3rem;
    transition: all 300ms linear;
}

.footer__social--responsive:hover{
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
}

.footer__Developer--responsive{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.7rem;
    margin-bottom: 0.8rem;
}

.footer__copy--responsive{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}

@media (min-width: 981px) {
    .layout__aside {
        display: none;
    }
}