.layout__menu--toggle {
    display: none;
}

.navbar__layout{
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    height: 8rem;
    width: 100%;
    background-color: var(--color-primary);
    z-index: 3;
    overflow: visible;
}

/*Opciones*/
.nav__list{
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 75rem;
}

.nav__item{
    position: relative;
    margin: 2rem 0.5rem;
}

.nav__item:last-child{
    margin-right: 2rem;
}

.nav__btn{
    position: relative;
    display: inline-block;
    height: 4rem;
    width: 17.5rem;
    background-color: var(--color-secondary);
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    color: var(--color-text);
    line-height: 4rem;
    border-radius: 6rem;
    transition: all 100ms ease-in-out;
}

.nav__btn:hover{
    line-height: 3.5rem;
    border: 2px solid var(--color-tertiary);
}

/*Logo*/
.navbar__logo{
    width: 5vw;
    height: auto;
    margin: auto 0 auto 3rem;
    /*margin-bottom: 2rem;*/
}