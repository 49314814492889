.experience__title{
    font-size: 5rem;
    color: black;
    position: relative;
}

.experience__color{
    display: inline-block;
    text-align: center;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
    margin-right: 0.7rem;
    background-color: var(--color-tertiary);
    border-radius: 6rem;
    color: black;
}