.download__button{
    height: 6.5rem;
    width: 27rem;
    background-color: transparent;
    border: 2px solid var(--color-tertiary);
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    color: var(--color-tertiary);
    line-height: 2;

    box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.22);
    transition: all 100ms ease-in-out;
}

.download__button:hover{
    font-size: 3.1rem;
    line-height: 1.9;
    border: 3.5px solid var(--color-tertiary);
    box-shadow: 10px 10px 10px -8px var(--color-tertiary);
}

@media(max-width: 980px) {
    .download__button{
        height: 6.5rem;
        width: 30rem;
        font-size: 3rem;
        line-height: 2;
    }
}