.about__title{
    font-size: 5rem;
    color: black;
    position: relative;
}

.about__color{
    display: inline-block;
    position: relative;
    text-align: center;
    height: 7rem;
    width: 7rem;
    line-height: 7rem;
    background-color: var(--color-tertiary);
    border-radius: 4rem;
    color: black;
}

.about__header{
    display: inline-block; 
    margin-bottom: 4.5rem;
}

/*INFORMACIÓN PERSONAL*/
.about__personal-info{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5rem;
    font-size: 2rem;
    line-height: 3rem;
}

.info__bio__p {
    opacity: 0;
    animation: fadeIn 500ms ease forwards;
}
  
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.personal-info__bio{
    flex-basis: 50%;
    text-align: justify;
}

.info__bio__p--2,
.info__bio__p--3,
.info__bio__p--4{
    margin-top: 1.5rem;
}

.personal-info__description{
    font-size: 1.5rem;
    line-height: 2.4rem;
}

.personal-info__data{
    flex-basis: 50%;
    padding: 0 1.5rem;
}

.personal-info__list{
    position: relative;
    top: 32rem;
    margin-left: 4rem;
}

.personal-info__option{
    margin-bottom: 1rem;
    animation: fadeIn 1s ease forwards;
}

.personal-info__title{
    margin-right: 0.5rem;
    color: var(--color-secondary);
    font-weight: 600;
}

/*HABILIDADES*/
.about__skills{
    width: 100%;
    margin-bottom: 7rem;
}

.skills__title{
    font-size: 3rem;
    color: black;
}

.skills__lenguages{
    margin-top: 2rem;
}

.lenguages__header,
.databases__header,
.programs__header{
    display: inline-block;
    padding-right: 1.2rem;
    margin-bottom: 2.5rem;
    position: relative;
}

.lenguages__title,
.databases__title,
.programs__title{
    font-size: 2rem;
    color: black;
    margin-top: 2.5rem;
}

.lenguages__container,
.databases__container,
.programs__container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.lenguages__lenguage,
.databases__database,
.programs__program{
    display: flex;
    align-items: center;
    flex-basis: calc(25% - 3rem);
    margin-right: 3rem;
    margin-bottom: 2.5rem;
}

.lenguage__icon,
.database__icon,
.program__icon{
    display: inline-block;
    margin-top: 1rem;
}

.icon__icon{
    color: var(--color-secondary);
    font-size: 3.8rem;
    transition: all 300ms ease-in-out;
}

.icon__icon:hover{
    font-size: 4.5rem;
}

.lenguage__title,
.database__title,
.program__title{
    display: inline-block;
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-left: 1rem;
    color: black;
}

.skills__databases,
.skills__programs{
    margin-top: 4.5rem;
}