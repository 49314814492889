.dataEducation__year{
    font-size: 1.5rem;
}

.dataEducation__name{
    font-weight: bold;
}

.data__university{
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

.university__name{
    font-weight: bold;
}

.dataEducation__description{
    font-size: 1.5rem;
    margin-top: 1.5rem;
}