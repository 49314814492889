/*IMPARES*/
.timeline__content--experience:nth-child(even){  
    text-align: left !important;
}

.data__position{
    font-size: 2rem;
    font-weight: bold;
}

.data__company{
    font-size: 1.8rem;
    margin-top: 0.8rem;
    font-weight: 500;
}

.data__date{
    font-size: 1.6rem;
    margin-top: 2.5rem;
}

.data__date span{
    font-weight: bold;
}

.data__tasks{
    display: block;
    width: auto;
    height: auto;
    text-align: justify;
    margin-top: 2rem;
    font-size: 1.6;
}

.tasks__title{
    font-weight: bold;
}

.tasks__list{
    margin-top: 1.2rem;
}

.list__item span{
    font-size: 2.5rem;
    font-weight: bold;
}