@media (max-width: 1700px) {
  .navbar__logo {
    width: 6vw;
  }
}

@media (max-width: 1300px) {
  .navbar__logo {
    width: 7vw;
  }

  .nav__btn{
    height: 3.7rem;
    width: 16rem;
    line-height: 3.7rem;
  }
}

@media (max-width: 1100px) {
  .nav__btn{
    height: 3.5rem;
    width: 14.5rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 1000px) {
  .navbar__logo {
    width: 8vw;
  }
}

@media (max-width: 980px) {
  .nav__list {
    display: none;
  }

  .layout__menu--toggle {
    display: block;
    position: absolute;
    z-index: 1000;
    right: 1rem;
    top: 1rem;
    width: 5.5rem;
    height: 5.5rem;
    line-height: 4.8rem;
    font-size: 4rem;
    text-align: center;
    background-color: var(--color-primary);
    color: white;
    border-radius: 3rem;
    cursor: pointer;
    transition: all 500ms ease-in-out;
  }

  .menu-toggle_icon {
      transition: all 100ms ease-in-out;
  }

  .nav__btn{
    height: 4.5rem;
    width: 20.5rem;
    font-size: 2.5rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 800px) {
  .navbar__logo {
    width: 9vw;
  }
}

@media (max-width: 650px) {
  .navbar__logo {
    width: 80px;
  }
}

@media (max-width: 600px) {
  .navbar__layout {
    display: block;
    overflow: hidden;
  }

  .navbar__logo {
    width: 75px;
  }
}

@media (max-width: 550px) {
  .navbar__logo {
    width: 70px;
  }
}

@media (max-width: 500px) {
  .navbar__logo {
    width: 65px;
  }
}

@media (max-width: 450px) {
  .navbar__logo {
    width: 50px;
  }
}
