@media (max-width: 1010px) {   
    .content__education{
        margin: 6rem;
        margin-left: 4rem;
    }
}

@media (max-width: 990px) {   
    .content__education{
        margin-left: 2rem;
    }
}

@media (max-width: 980px) {   
    .content__education{
        margin: 6rem;
    }

    .education__title{
        font-size: 6rem;
    }

    .education__color{
        height: 9rem;
        width: 9rem;
        line-height: 9rem;
    }
}