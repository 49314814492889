@media (max-width: 980px) {
    .content__page{
        width: 100vw;
    }

    .form-header__title{
        font-size: 3.4rem;
    }

    .form__input:first-child{
        margin-top: 1.5rem;
    }

    .form__input{
        height: 5.2rem;
        font-size: 2.35rem;
        line-height: 2.7rem;
        margin-bottom: 0.8rem;
    }

    .form__input--textarea{
        height: 100%;
    }

    .form__input:focus~label,
    .form__input:not(:placeholder-shown)~label{
        top: -1rem;
        font-size: 2rem;
    }

    .form__button{
        font-size: 2.5rem;
    }
}

@media (max-width: 600px) {
    .form__group--1{
        flex-basis: 100%;
        margin-right: 2rem;
    }
    
    .form__group--2{
        flex-basis: 100%;
        margin-right: 2rem;
    }
}

@media (max-width: 365px) {
    .form__group--1{
        flex-basis: 100%;
        margin-right: 2rem;
    }
    
    .form__group--2{
        flex-basis: 100%;
        margin-right: 2rem;
    }
}

@media (max-width: 360px) {
    .captcha{
        margin-left: -1rem;
    }
}

@media (max-width: 350px) {
    .captcha{
        margin-left: -1.5rem;
    }
}

@media (max-width: 340px) {
    .captcha{
        margin-left: -2rem;
    }
}

@media (max-width: 330px) {
    .captcha{
        margin-left: -3rem;
    }
}

@media (max-width: 325px) {
    .captcha{
        margin-left: -4rem;
    }
}

@media (max-width: 320px) {
    .captcha{
        margin-left: -4.5rem;
    }
}

@media (max-width: 315px) {
    .captcha{
        margin-left: -5.5rem;
    }
}