@media (max-width: 1250px) {   
    .timeline__content{
        flex-basis: calc(45% - 10rem);
    }
}

@media (max-width: 1150px) {   
    .timeline__content{
        flex-basis: calc(50% - 10rem);
    }
}

@media (max-width: 1025px) {   
    .timeline__content{
        flex-basis: calc(50% - 10rem);
        margin-left: 4.5rem;
    }
    
    .timeline__content:nth-child(odd)::before{
        width: 15px;
        height: 15px;
        left: -25px;
        top: 3px;
    }

    .timeline__content:nth-child(even)::before{
        width: 15px;
        height: 15px;
        right: -25px;
        top: 3px;
    }
}

@media (max-width: 980px) {   
    .timeline__content{
        margin-left: 10rem;
        margin-top: 3rem;
        flex-basis: calc(50% - 10rem);
    }
}

@media (max-width: 930px) {   
    .timeline__content{
        flex-basis: 100%;
        margin-left: 10rem;
    }

    .timeline__content:nth-child(even){
        border-radius: 0px 20px 20px;
        text-align: left;
        box-shadow: 0 4px 30px grey;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .timeline__content:nth-child(even)::before{
        width: 15px;
        height: 15px;
        left: -25px;
        top: 3px;
    }

    .timeline__content:nth-child(even)::after{
        content: '';
        position: absolute;
        background: var(--color-tertiary);    
        width: 10px;
        height: 2px;
        left: -10px;
        top: 10px;
    }
}