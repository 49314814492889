@media (max-width: 980px) {
    .dataEducation__year{
        font-size: 3rem;
        line-height: 3.7rem;
    }
    
    .data__university{
        font-size: 3rem;
        margin-top: 3rem;
        line-height: 3.5rem;
    }
    
    .dataEducation__description{
        font-size: 3rem;
        margin-top: 3rem;
        line-height: 3.7rem;
    }
}