.timeline{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
    max-width: 80vw;
}

.timeline__content{
    position: relative;
    height: auto;
    margin-left: 4.5rem;
    margin-top: 3rem;
    flex-basis: calc(50% - 10rem);

    background-color: var(--color-primary);
    opacity: 1;

    animation-name: __show;
    animation-duration: 1s;
    animation-timing-function: linear;
}

.timeline__content:hover{
    scale: 1.05;
}

.content__data{
    width: 100%;
    color: white;
    padding: 1.5rem;
}

/*PARES*/
.timeline__content:nth-child(odd){
    box-shadow: 0 4px 30px grey;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0px 20px 20px;
}
.timeline__content:nth-child(odd)::before{
    content: '';
    position: absolute;
    background: var(--color-tertiary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    left: -30px;
}
.timeline__content:nth-child(odd)::after{
    content: '';
    position: absolute;
    background: var(--color-tertiary);    
    width: 10px;
    height: 2px;
    left: -10px;
    top: 10px;
}

/*IMPARES*/
.timeline__content:nth-child(even){
    border-radius: 20px 0px 20px 20px;  
    text-align: right;
    box-shadow: 0 4px 30px grey;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.timeline__content:nth-child(even)::before{
    content: '';
    position: absolute;
    background: var(--color-tertiary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    right: -30px;
}
.timeline__content:nth-child(even)::after{
    content: '';
    position: absolute;
    background: var(--color-tertiary);    
    width: 10px;
    height: 2px;
    right: -10px;
    top: 10px;
}

/*cubic-bezier(.99,-.7,.2,1.9)*/
@keyframes __show {
    0% { 
        transform: translateY(500px);
        opacity: 0;
    }    
    100% {
        transform: translateY(0);
        opacity: 1;
    }    
}