/*ESTRUCTURA PRINCIPAL*/
.layout__card{
    position: relative;
    min-width: 1290px;
    width: 100%;
    height: calc(100vh - 8rem);
    min-height: calc(100vh - 8rem);
    background-color: var(--color-primary);
    display: grid;
    grid-template-areas: 
        "aside content";
    grid-template-columns: 25% 75%;
}

/*BARRA LATERAL*/
.card__aside{
    grid-area: aside;
    overflow: auto;
    overflow-x: hidden;
}

.aside__personal-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    min-height: 100%;
    min-width: 30rem;

    background-color: var(--color-primary);
    color: white;
}

.personal-info__container-image::after{
    content: "";
    position: absolute;
    top: -1.5rem;
    left: -1.5rem;
    z-index: -1;
    width: 28rem;
    height: 27rem;
    border: 4px solid var(--color-tertiary);
    border-radius: 20rem;
}

.personal-info__container-image {
    position: relative;
    display: inline-block;
    width: 25rem;
    height: 24rem; /* Ajuste de la altura para mantener el espacio */
    margin-top: -10rem;
    perspective: 100rem; /* Perspectiva para el efecto 3D */
}

.personal-info__image {
    width: 100%;
    height: 100%; /* Ajuste de la altura para mantener el espacio */
    border-radius: 15rem;
    backface-visibility: hidden; /* Evita el parpadeo durante la rotación */
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335); /* Efecto de aceleración */
}

.personal-info__image-front {
    transform: rotateY(0deg);
}

.personal-info__image-back {
    transform: rotateY(180deg);
}

.personal-info__container-image:hover .personal-info__image-front {
    transform: rotateY(-180deg); /* Girar 180 grados en el eje Y */
}

.personal-info__container-image:hover .personal-info__image-back {
    transform: rotateY(0deg); /* Volver a la posición inicial */
}

.personal-info__name{
    margin-top: 4rem;
    font-size: 3.7rem;
    font-weight: bold;
}

.personal-info__job{
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 2.2rem;
}

.personal-info__links{
    margin-bottom: 3.5rem;
}

.links__social{
    margin-top: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.social__option{
    height: 4.5rem;
    width: 4.5rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    opacity: 0.9;
    border-radius: 3rem;
    transition: all 100ms linear;
}

.social__option:hover{
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
}

.social__link{
    color: white;
}

.personal-info__footer{
    position: absolute;
    bottom: 2rem;
}

.footer__Designer{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.7rem;
    margin-bottom: 1rem;
}

.footer__social{
    height: 3rem;
    width: 12.5rem;
    color: white;
    line-height: 3rem;
    opacity: 0.9;
    border-radius: 3rem;
    transition: all 300ms linear;
}

.footer__social:hover{
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
}

.footer__Developer{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.7rem;
    margin-bottom: 0.8rem;
}

.footer__copy{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}

/*CONTENEDOR PRINCIPAL*/
.card__content{
    grid-area: content;
    height: 100%;
    min-height: 100%;
    background-color: white;
    border-radius: 6rem 0 0 6rem;
    overflow: scroll;
}

.content__page{
    min-height: 100%;
    background-color: var(--color-principal);
    border-radius: 3.2rem;
}

/* ESTILOS GENERALES */
.content__page{
    display: block;
    height: auto;
    min-height: 100%;
}

/* Imagen pattern */
.content__pattern{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50rem;
    filter: saturate(5000%);
    z-index: 1;
    pointer-events: none;
}