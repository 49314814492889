@media (max-width: 1500px) {
    /*BARRA LATERAL*/
    .card__aside{
        grid-area: aside;
    }

    .personal-info__container-image::after{
        width: 25rem;
        height: 24rem;
    }

    .personal-info__container-image {
        width: 22rem;
        height: 21rem; /* Ajuste de la altura para mantener el espacio */
    }

    .personal-info__name{
        font-size: 3.3rem;
    }

    .personal-info__job{
        font-size: 2rem;
    }

    .personal-info__links{
        margin-bottom: 3rem;
    }

    .social__option{
        height: 4rem;
        width: 4rem;
        font-size: 3rem;
    }

    .footer__Designer{
        font-size: 1.5rem;
    }

    .footer__Developer{
        font-size: 1.5rem;
    }
}

@media (max-width: 1350px) {
    .personal-info__name{
        font-size: 3rem;
    }

    .personal-info__job{
        font-size: 1.8rem;
    }

    .social__option{
        height: 3.7rem;
        width: 3.7rem;
        font-size: 2.7rem;
    }
}

@media (max-width: 980px) {
    /*ESTRUCTURA PRINCIPAL*/
    .layout__card{
        display: grid;
        grid-template-areas: 
            "aside aside"
            "content content";
        grid-template-columns: 100%;
        overflow: scroll;
    }

    .content__page{
        overflow: visible;
    }

    /*BARRA LATERAL*/
    .card__aside{
        grid-area: aside;
        height: 30rem;
        min-height: 30rem;
        max-height: 30rem;
        width: 100vw;
    }

    .aside__personal-info{
        display: grid;
        grid-template-areas: 
            "photo info footer"
            "photo info footer"
            "photo icons footer"
            "photo button footer";
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: space-between;
    }

    .personal-info__container-image::after{
        width: 24rem;
        height: 23rem;
    }

    .personal-info__container-image {
        grid-area: photo;
        width: 21rem;
        height: 20rem; /* Ajuste de la altura para mantener el espacio */
        margin-top: 0rem;
        margin-left: 3rem;
    }

    .personal-info__general {
        grid-area: info;
        display: grid;
        grid-template-areas: 
            "name"
            "job";
        grid-template-columns: 100%;
    }

    .personal-info__name{
        grid-area: name;
        margin-top: 0rem;
    }

    .personal-info__job{
        grid-area: job;
    }

    .personal-info__links{
        grid-area: icons;
        margin: -12rem auto 0 auto;
    }

    .links__social{
        margin-top: 0rem;
        text-align: center;
    }
    
    .personal-info__buttons{
        grid-area: button;
        position: relative;
        margin: -10rem auto 0 auto;
    }

    .personal-info__footer{
        grid-area: footer;
        position: relative;
        bottom: -10rem;
        right: 1rem;
    }

    .footer__Developer{
        display: inline;
    }

    /*CONTENEDOR PRINCIPAL*/
    .card__content{
        height: auto;
        min-height: auto;
        border-radius: 6rem 0 0 0rem;
        overflow: visible;
    }

    .content__about{
        padding-bottom: 0px;
    }
}

@media (max-width: 600px) {
    /*BARRA LATERAL*/
    .card__aside{
        grid-area: aside;
        height: 30rem;
        min-height: 30rem;
        max-height: 30rem;
        width: 100vw;
    }

    .personal-info__container-image::after{
        width: 22rem;
        height: 21rem;
    }

    .personal-info__container-image {
        width: 19rem;
        height: 18rem; /* Ajuste de la altura para mantener el espacio */
    }

    .personal-info__name{
        font-size: 2.7rem;
    }

    .personal-info__job{
        font-size: 1.7rem;
    }
}

@media (max-width: 550px) {
    .personal-info__container-image::after{
        width: 21rem;
        height: 20rem;
    }

    .personal-info__container-image {
        width: 18rem;
        height: 17rem; /* Ajuste de la altura para mantener el espacio */
    }

    .personal-info__footer{
        right: 0.5rem;
        bottom: -8rem;
    }

    .personal-info__name{
        font-size: 3.5rem;
    }

    .personal-info__job{
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .footer__Designer{
        flex-direction: column;
        font-size: 1.4rem;
    }

    .footer__social{
        width: 10.5rem;
    }

    .footer__Developer{
        display: inline-block;
        font-size: 1.4rem;
        max-width: 12rem;
        height: 3rem;
    }
}

@media (max-width: 475px) {
    .personal-info__container-image::after{
        width: 20rem;
        height: 19rem;
    }

    .personal-info__container-image {
        width: 17rem;
        height: 16rem; /* Ajuste de la altura para mantener el espacio */
    }
}

@media (max-width: 415px) {
    .personal-info__name{
        font-size: 3rem;
        line-height: 3.3rem;
    }

    .personal-info__job{
        font-size: 2rem;
        line-height: 2.5rem;
    }
}