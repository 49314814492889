@media (max-width: 980px) {
    .data__position{
        font-size: 3rem;
        line-height: 4rem;
    }
    
    .data__company{
        font-size: 2.8rem;
        margin-top: 2.5rem;
    }
    
    .data__date{
        font-size: 2.6rem;
        line-height: 3.2rem;
        margin-top: 2.9rem;
    }
    
    .data__tasks{
        margin-top: 2rem;
        font-size: 2.6rem;
        line-height: 3.5rem;
    }
    
    .list__item span{
        font-size: 3.5rem;
        line-height: 4rem;
    }
}